import React from 'react';
import '../styles/table.css';

const redDeer = {
  Blackfalds: [75, 125],
  Didsbury: [100],
  Eckville: [50],
  'Fort Saskatchewan': [275, 475],
  Lacombe: [75],
  Leduc: [100, 150],
  'Leduc County': [162],
  'Mountain View County': [150],
  Penhold: [75],
  Ponoka: [50],
  'Red Deer': [160],
  'Red Deer County': [100],
  Sundre: [125],
  Trochu: [50],
};

const calgary = {
  Airdrie: [150],
  Bighorn: [150],
  Calgary: [189],
  Chestermere: [125],
  Cochrane: [150],
  Crossfield: [108, 160],
  'Diamond Valley': [100, 150],
  Didsbury: [100],
  'Foothills County': [250],
  'High River': [100, 200],
  'Mountain View County': [150],
  Nanton: [55],
  Okotoks: [175, 350],
  'Rocky View County': [150],
  Strathmore: [160, 300],
  Sundre: [125],
  Trochu: [50],
  'Taber, MD': [42],
  'Village of Rockyford': [120],
  'Village of Stirling': [65],
  Vulcan: [75],
  'Vulcan County': [75],
  'Wheatland County': [100],
  'Willow Creek MD': [100],
};

const ComplianceCosts = () => (
  <>
    <p>Below is the current cost of a Certificate of Compliance for the RPR in Red Deer and surrounding areas. January 2024.</p>
    <table className="table-price">
      <thead>
      <tr>
        <th>Municipality</th>
        <th>Rate</th>
        <th>Rush</th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(redDeer).map(([city, [rate, rush]]) => (
        <tr key={city}>
          <td>{city}</td>
          <td className="text-right">${rate}.00</td>
          {rush ? <td className="text-right">${rush}.00</td> : <td/>}
        </tr>
      ))}
      </tbody>
    </table>
    <br />

    <p>Below is the current cost of a Certificate of Compliance for the RPR in Calgary and surrounding areas. January 2024.</p>
    <table className="table-price">
      <thead>
      <tr>
        <th>Municipality</th>
        <th>Rate</th>
        <th>Rush</th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(calgary).map(([city, [rate, rush]]) => (
        <tr key={city}>
          <td>{city}</td>
          <td className="text-right">${rate}.00</td>
          {rush ? <td className="text-right">${rush}.00</td> : <td/>}
        </tr>
      ))}
      </tbody>
    </table>
  </>
);

export default ComplianceCosts;
