import { Link } from 'gatsby';
import React from 'react';
import ComplianceCosts from '../../../components/ComplianceCosts';
import { Layout } from '../../../components/Layout';

const ComplianceCostsPage = () => (
  <Layout
    title="Compliance Costs for Calgary & Red Deer | Axiom Geomatics"
    description="Real Property Report (RPR) Certificate of Compliance costs for various cities and municipalities"
    keywords=""
  >
    <main>
      <h1>Compliance Costs</h1>

      <p>
        A <Link to="/services/residential-surveys/real-property-reports">Real Property Report</Link>{' '}
        (RPR's) provides a comprehensive, visual representation of the improvements on a property,
        helping to identify any potential issues or discrepancies that may need to be addressed
        before a sale is finalized. Land surveyors note the location of many exterior features such
        as sheds, fences, decks, retaining walls, etc. to ensure accurate representation of the
        home, structures and parcel of land.
      </p>

      <p>
        A Surveyors’ Real Property Report (RPR), can be submitted to a Municipality (eg. Red Deer or
        Calgary) for a Certificate or Stamp of Compliance. This evaluation process compares the
        structures on the property to the bylaws created by the municipality. If the property
        doesn't violate the bylaws, the “stamp” or “letter” of compliance will be issued and attest
        that a specific property complies with the local zoning bylaws, land use regulations, and
        other applicable laws and regulations. The municipality compares the RPR with the specific
        local zoning that applies to that specific parcel and land use regulations to ensure that
        the property adheres to the requirements. These requirements can vary by jurisdiction and
        neighborhood and even by age of the house. This certificate serves as an assurance that,
        based on the survey conducted, the property conforms to the applicable laws and regulations.
        Axiom Geomatics offers, for an additional service fee, to submit the RPRs on our clients’
        behalf. The costs for this compliance letter or stamp is set by the municipalities, and many
        Southern Alberta municipalities are listed below.
      </p>
    </main>

    <aside style={{ minWidth: '400px' }}>
      <ComplianceCosts />
    </aside>
  </Layout>
);

export default ComplianceCostsPage;
